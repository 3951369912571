import $ from 'jquery'; // Importa jQuery
import 'datatables.net-dt'; // Importa DataTables
import 'datatables.net-autofill-dt'; // Importa DataTables Autofill

$(document).ready(function() {
    $('#divisionTable').DataTable({
    autofill: true,
    scrollY: '461px',
    scrollCollapse: true,
    scrollX: true,
    order: [],
    lengthMenu: [
    [20, 50, 100, -1],
    [20, 50, 100, 'TUTTI'],
    ],
    language: {
        search: "Cerca",
        lengthMenu: 'Visualizza _MENU_ Divisioni',
        zeroRecords: 'La ricerca non ha portato alcun risultato.',
        info: 'Pagina _PAGE_ di _PAGES_',
        infoEmpty: 'Nessuna Divisione disponibile',
        infoFiltered: '(filtrata da _MAX_ Divisioni totali)',
        loadingRecords: "Caricamento..",
        paginate: {
            first:    "Primo",
            last:    "Ultimo",
            next:    "Successivo",
            previous: "Precedente"
        },
    },  
    drawCallback: function() {
        $('#visibilityTable').removeClass('invisible');
    }
    
    });
});