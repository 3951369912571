import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-autofill-dt';
var dataTable;
var lastWindowWidth = $(window).width();
var initializationWindowWidth = false;
$(document).ready(function() {
    dataTable = $('#orderTable').DataTable({ 
        searching: true,
        autofill: true,
        scrollY: '461px',
        scrollCollapse: true,
        scrollX: true,
        order: [0, 'desc'],
        processing: true,
        dom: 'lfrtip<"custom-pagination"ip>',
        columns: [
            { data: 'codice', name: 'codice' },
            { data: 'divisione', name: 'divisione' },         
            { data: 'cliente', name: 'cliente' },
            { data: 'note', name: 'note'},
            { data: 'matricola', name: 'matricola' },
            { data: 'description', name: 'description' },
            { data: 'ordine_lavoro', name: 'ordine_lavoro' },
            { 
                data: 'inattivo', 
                name: 'inattivo'
                // ,
                // render: function(data, type, full, meta) {
                //     if (data == '1') {
                //         data = '<span>CHIUSO</span>';
                //     } else if (data == '0') {
                //         data = '';
                //     }
                //     return data;
                // }
            },
            { data: 'id', name: 'id'}

            //
            // { data: 'Cd_tipoMacchina', name: 'Cd_tipoMacchina' },
            // { data: 'Data_Emissione', name: 'Data_Emissione' },
            // { data: 'Data_Ingresso', name: 'Data_Ingresso' },
            // { data: 'Data_Uscita_Prevista', name: 'Data_Uscita_Prevista' },
            // { data: 'Qta_Ingresso',  name: 'Qta_Ingresso' },
            // { data: 'Qta_Uscita',  name: 'Qta_Uscita' },
            // { data: 'Qta_Ordine',  name: 'Qta_Ordine' },
            // { data: 'Attivo', name: 'Attivo' }
        ],
        // columnDefs: [
        //     { targets: [6, 7, 8, 9, 10, 11, 12, 13], visible: false }
        // ],
        serverSide: true,
        ajax: '/gestionale/ordini',
        lengthMenu: [
            [20, 50, 100],
            [20, 50, 100],
        ],
        language: {
            search: "Cerca",
            lengthMenu: 'Visualizza _MENU_ Ordini',
            zeroRecords: 'La ricerca non ha portato alcun risultato.',
            info: 'Pagina _PAGE_ di _PAGES_',
            infoEmpty: 'Nessun Ordine disponibile',
            infoFiltered: '(filtrata da _MAX_ Ordini totali)',
            loadingRecords: "Caricamento..",
            paginate: {
                first:    "Primo",
                last:    "Ultimo",
                next:    "Successivo",
                previous: "Precedente"
            },
        },  
        createdRow: function(row, data, dataIndex) {
            var tempoStimatoParam = data.tempo_stimato !== null ? data.tempo_stimato : "";

            $(row).attr('onclick', "selezionaRecord('" + data.codice +"', '" + data.cliente +"', '" + data.divisione +"', '" + data.matricola +"','" + data.codice_aereo + "', '" + data.description +"', '" + data.ordine_lavoro +"', '" + data.data_emissione +"','" + data.data_ingresso +"','" + data.data_uscita_prevista +"','" + data.quantita_ordine+"', '" + data.note +"','" + tempoStimatoParam +"')");
           
            $(row).find('td').each(function(index) {
                var cdOlInternoValue = data.codice;
                var className = cdOlInternoValue;
                $(this).addClass(className);
            });
        },
        rowCallback: function(row, data, index) {
            $(row).find('td').addClass('p-0'); 
            if (selectedBefore) {
                $(row).find('td.' + selectedBefore).addClass('bg_main_light');
            }   
        },
        drawCallback: function() {
            var api = this.api();
            var varNum = 0;

            api.rows().every(function() {
                var row = this.node();
                let idOrder = null; 
                for (let i = 0; i < 9; i++) {
                    varNum ++;
                    let td = $(row).find('td:eq(' + i + ')');
                    if(i == 0){
                        idOrder = td.text().trim();
                    }
                    if(i == 2){
                        td.addClass('text-center');
                    }
                    if (i == 7) {
                        let text = td.text().trim();
                        if (text === '1') {
                            td.addClass('bg-danger');
                            // td.text('CHIUSO');
                            var $link = $('<a>').text('RIAPRI').on('click', function() {
                                apriOrdine(idOrder);
                            }).css('display', 'block').css('width', '100%').css('cursor', 'pointer');
                        }else{
                            var $link = $('<a>').text('CHIUDI').on('click', function() {
                                chiudiOrdine(idOrder);
                            }).css('display', 'block').css('width', '100%').css('cursor', 'pointer');
                        }
                        td.empty().append($link);
                        td.addClass('text-center');
                    }
                    if(i == 8){
                        let codice_ordine = td.text().trim();
                        var $link = $('<a>').text('Scarica')
                        .css('display', 'block')
                        .css('width', '100%')
                        .css('cursor', 'pointer')
                        .attr('href', '/export-ordine/' + encodeURIComponent(codice_ordine));
                        var $img = $('<img>').addClass('arrowsTable').attr('src', 'data:image/png;base64, ' + excelLogo);
                        $link.append($img);
                        td.empty().append($link);
                        td.addClass('text-center');
                    }
                    let id = 'tablecellordini' + (i + 1) + '_'+ (varNum);
                    td.addClass('tablecellordini'+ (i + 1)).attr('id', id);
                }
            });   

            dataTable.columns.adjust();
            $('#visibilityTable').removeClass('invisible');

        }

    });

    $(".custom-pagination").html('<div style="float: right;text-align: right;padding-top: 0.35em;"><form id="customPageForm"><input class ="paginate_button p-1" type="text" id="customPageInput" placeholder="Inserisci numero pagina"><button type="button" class="paginate_button p-1 rounded-1" id="customPageButton">Vai</button></form></div>');

    $(document).on('click', '#customPageButton', function() {
        goToCustomPage();
    });
    $(document).on('submit', '#customPageForm', function(e) {
        e.preventDefault();
        goToCustomPage();
    });

    setInterval(function() {
        var currentWindowWidth = $(window).width();
        if (currentWindowWidth !== lastWindowWidth) {
            lastWindowWidth = currentWindowWidth;
            if (initializationWindowWidth == true) {
                dataTable.draw();
            }else{
                initializationWindowWidth = true;
            }
        }
    }, 500);
});
function goToCustomPage() {
    var customPageNumber = parseInt($('#customPageInput').val());

    if (!isNaN(customPageNumber) && customPageNumber > 0) {
        dataTable.page(customPageNumber - 1).draw(false);
    }
}


$('#orderTable').on('mouseenter', 'td', function() {
    var td = $(this);
    var id = td.attr('id');
    var parts = id.split('_');

    if (isEllipsisActive(td[0])) {
        handleHover(id, 'full_width', parts[0]); 
    }
}).on('mouseleave', 'td', function() {
    var td = $(this);
    var id = td.attr('id');
    var parts = id.split('_');
    handleHover(id, parts[0], 'full_width'); 
});

function handleHover(id, addClass, removeClass) {
    $('#' + id).addClass(addClass).removeClass(removeClass);
}

function isEllipsisActive(element) {
    var style = window.getComputedStyle(element, null);
    return style.getPropertyValue('overflow') === 'hidden' &&
           style.getPropertyValue('text-overflow') === 'ellipsis' &&
           element.scrollWidth > element.clientWidth;
}





