import $ from 'jquery'; 
import 'datatables.net-dt'; 
import 'datatables.net-autofill-dt';

$(document).ready(function() {
    $('#employeeTable').DataTable({
    autofill: true,
    scrollY: '461px',
    scrollCollapse: true,
    scrollX: true,
    order: [],
    lengthMenu: [
    [20, 50, 100, -1],
    [20, 50, 100, 'TUTTI'],
    ],
    language: {
        search: "Cerca",
        lengthMenu: 'Visualizza _MENU_ Addetti',
        zeroRecords: 'La ricerca non ha portato alcun risultato.',
        info: 'Pagina _PAGE_ di _PAGES_',
        infoEmpty: 'Nessun Addetto disponibile',
        infoFiltered: '(filtrata da _MAX_ Addetti totali)',
        loadingRecords: "Caricamento..",
        paginate: {
            first:    "Primo",
            last:    "Ultimo",
            next:    "Successivo",
            previous: "Precedente"
        },
    },  
    drawCallback: function() {
        $('#visibilityTable').removeClass('invisible');
    }    
    });
});