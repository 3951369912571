import $ from 'jquery'; // Importa jQuery
$(".AlertDown").click(function(){
    setTimeout(function(){
      $("#alertsContainer").fadeOut(200);
    }, 500);
  });

$(".AlertDown").click(function(){
  setTimeout(function(){
    $("#myAlertsContainer").fadeOut(200);
  }, 500);
});


setTimeout(fade_out, 2500);
function fade_out() {
  $("#mySuccessAlert").fadeOut(200);
}



// var idleTime = 0;
// // var idleTimeout = 180 * 1000; 

// $(document).on('mousemove keypress', function () {
//   idleTime = 0;
// });
// var avoidRepeatLogout = false;

// var csrfToken;

// setInterval(function () {
//   if (window.authenticated) {

//     idleTime += 5000;

//     // console.log(idleTime, idleTimeout);
    
//     if (idleTime >= idleTimeout && avoidRepeatLogout == false) {
//       avoidRepeatLogout = true;
    
//       csrfToken = $('meta[name="csrf-token"]').attr('content');

//       var logoutForm = document.createElement('form');
//       logoutForm.action = '/logout'; 
//       logoutForm.method = 'POST';
//       logoutForm.style.display = 'none';
      
//       var csrfInput = document.createElement('input');
//       csrfInput.type = 'hidden';
//       csrfInput.name = '_token';
//       csrfInput.value = csrfToken;
//       logoutForm.appendChild(csrfInput);

//       document.body.appendChild(logoutForm);
//       logoutForm.submit();
      
//     }
//   }
// }, 5000);