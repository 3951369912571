require('./bootstrap');
require( 'datatables.net-dt' );
require( 'datatables.net-autofill-dt' );
require( './employee' );
require( './plane' );
require( './macrophase' );
require( './phase' );
require( './activity' );
require( './division' );
require( './partnumber' );
require( './material' );
require( './movement' );
require( './order' );
require( './batchScadenza' );
require( './batchScaduti' );
require('./bootstrap3-typehead');
require( './script' );