import $ from 'jquery'; // Importa jQuery
import 'datatables.net-dt'; // Importa DataTables
import 'datatables.net-autofill-dt'; // Importa DataTables Autofill

$(document).ready(function() {
    $('#movementTable').DataTable({
        searching: true,
        autofill: true,
        scrollY: '461px',
        scrollCollapse: true,
        scrollX: true,
        order: [0, 'desc'],
        processing: true,
        columns: [
            { data: 'id', name: 'id' },
            { data: 'codice_risorsa', name: 'codice_risorsa' },
            {   
                data: 'created_at',
                name: 'created_at',
                render: function(data, type, full, meta) {
                    var date = new Date(data);
                    var formattedDate = padZero(date.getDate()) + '/' + padZero(date.getMonth() + 1) + '/' + date.getFullYear();

                    var titleDate = date.toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
                    
                    return '<abbr title="' + titleDate + '">' + formattedDate + '</abbr>';
                }
            },
            {data: 'codice_materiale', name: 'codice_materiale'},
            {data: 'codice_lotto', name: 'codice_lotto'},
            {
                data: 'quantita',
                name: 'quantita',
                render: function(data, type, full, meta) {
                
                    if (type === 'display') {
                        var formattedQuantity = formatQuantity(data);
                        return formattedQuantity;
                    }
                    return data;

                }
            },
            {data: 'segno', name: 'segno'},
            {
                data: 'codice_magazzino',
                name: 'codice_magazzino',
                render: function(data, type, full, meta) {
                    if (data == '1') {
                        data = '<span>Principale</span>';
                    } else if (data == '0') {
                        data = '<span>Produzione</span>';
                    }
                    return data;
                }
            },
            {data: 'causale', name: 'causale'},
        ],
        serverSide: true,
        ajax: '/gestionale/magazzino',
        lengthMenu: [
            [15, 25, 50, 100],
            [15, 25, 50, 100],
        ],
        language: {
            search: "Cerca",
            lengthMenu: 'Visualizza _MENU_ Magazzino',
            zeroRecords: 'La ricerca non ha portato alcun risultato.',
            info: 'Pagina _PAGE_ di _PAGES_',
            infoEmpty: 'Nessuna Movimento disponibile',
            infoFiltered: '(filtrata da _MAX_ Movimenti totali)',
            loadingRecords: "Caricamento..",
            paginate: {
                first:    "Primo",
                last:    "Ultimo",
                next:    "Successivo",
                previous: "Precedente"
            },
        },  
        drawCallback: function() {
            var api = this.api();
 
            api.rows().every(function() {
                var row = this.node();
                var id = this.data().id;
                $(row).attr('onclick', 'selezionaRecord('+ id +')');
                $(row).find('td').each(function() {
                    $(this).addClass(`${id}`);
                });
                $(row).find('td:eq(6)').addClass('p-0 text-center');
                var $td6 = $(row).find('td:eq(6)');
                var segnoValue6 = $td6.text().trim();
                
                if (segnoValue6 === '1') {
                    var $img = $('<img>').addClass('arrowsTable').attr('src', 'data:image/png;base64, ' + arrowTop);
                } else if (segnoValue6 === '0') {
                    var $img = $('<img>').addClass('arrowsTable').attr('src', 'data:image/png;base64, ' + arrowBottom);
                }
                
                $td6.empty().append($img);


            });
            $('#visibilityTable').removeClass('invisible');
        },
        rowCallback: function(row, data, index) {
            $(row).find('td').css({
                'padding': '0.23rem'
            })
        }
        
        
    });
    
    
});

function padZero(number) {
    return (number < 10 ? '0' : '') + number;
}

// function htmlDecode(input) {
//     var doc = new DOMParser().parseFromString(input, "text/html");
//     return doc.documentElement.textContent;
// }
function formatQuantity(quantity) {
    var formatted = parseFloat(quantity).toLocaleString('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatted;
}